
( function ( $ ) {
	'use strict';

  
	$( function() {

    // document.addEventListener('wpcf7mailsent', function(event) {
    //   if (event.detail.contactFormId == '591') {
    //     location = document.location.origin + '/dziekujemy/';
    //   } else if (event.detail.contactFormId == '1080') {
    //     location = document.location.origin + '/dziekujemy-doradca/';
    //   } else if (event.detail.contactFormId == '1119') {
    //     location = document.location.origin + '/dziekujemy-sidebar/';
    //   } else if (event.detail.contactFormId == '836') {
    //     location = document.location.origin + '/dziekujemy-slider/';
    //   } else if (event.detail.contactFormId == '836') {
    //     location = document.location.origin + '/dziekujemy-zdolnosc/';
    //   } else {
    //      location = document.location.origin + '/dziekujemy/?no-detail';
    //   }
    // }, false);

    $(document).ready(function() {
      $("[data-link]").click(function() {
        window.location.href = $(this).attr("data-link");
        return false;
      });
    });

    

    jQuery('#wpmtst_new_field_3').on('change', function(e) {
      console.log('check!');
      if(jQuery(this).prop('checked')) {
         if(jQuery('#wpmtst_zgoda_polityka_prywatnosci').prop('checked') == false) {
          jQuery('#wpmtst_zgoda_polityka_prywatnosci').click();
        }
      	 if(jQuery('#wpmtst_new_field').prop('checked') == false) {
          jQuery('#wpmtst_new_field').click();
        }
         if(jQuery('#wpmtst_new_field_2').prop('checked') == false) {
          jQuery('#wpmtst_new_field_2').click();
        }
      } else {
          // something else when not
		if(jQuery('#wpmtst_zgoda_polityka_prywatnosci').prop('checked') == true) {
          jQuery('#wpmtst_zgoda_polityka_prywatnosci').click();
        }
      	 if(jQuery('#wpmtst_new_field').prop('checked') == true) {
          jQuery('#wpmtst_new_field').click();
        }
         if(jQuery('#wpmtst_new_field_2').prop('checked') == true) {
          jQuery('#wpmtst_new_field_2').click();
        }
      }
  });

    // $('.hamburger').click(function(e) {
    //   e.preventDefault();
    //   $(this).toggleClass('is-active');
    //   var menu;
    //   menu = document.getElementById('menu');
    //   $(menu).slideToggle().toggleClass('open');
    // });

    // window.onscroll = function() {myFunction()};
    //   var header = document.getElementById("header");
    //   var sticky = header.offsetTop;
    //   function myFunction() {
    //     if (window.pageYOffset > sticky) {
    //       header.classList.add("sticky");
    //     } else {
    //       header.classList.remove("sticky");
    //     }
    //   }

function isIOSorSafari() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    || (/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
}
// if(isIOSorSafari()) {
//     var style = document.createElement('style');
//     style.type = 'text/css';
//     style.innerHTML = '@media (max-width: 997px) { #menu { max-height: 2200px !important; } }';
//     document.getElementsByTagName('head')[0].appendChild(style);
// } else {
//     window.onscroll = function() {myFunction()};
//     var header = document.getElementById("header");
//     var sticky = header.offsetTop;
//     function myFunction() {
//       if (window.pageYOffset > sticky) {
//         header.classList.add("sticky");
//       } else {
//         header.classList.remove("sticky");
//       }
//     }
// }

    $('.accordion .toggle').click(function(e) {
      e.preventDefault();
    
      var $this = $(this);
      
      if ($this.next().hasClass('show')) {
          $this.removeClass('open');  
          $this.next().removeClass('show');
          $this.next().slideUp(350);
      } else {
          $this.parent().parent().find('li a').removeClass('open');
          $this.parent().parent().find('li .inner').removeClass('show');
          $this.parent().parent().find('li .inner').slideUp(350);
          $this.addClass('open');  
          $this.next().toggleClass('show');
          $this.next().slideToggle(350);
      }
  });

  jQuery('.label-link').on('click', function(e) {
    
    if(jQuery(this).data('show')) {
    	e.preventDefault();
        jQuery('.'+jQuery(this).data('show')).slideToggle();
    }


  });


  $('.js-search-btn').on('click', function(e) {
    $(this).siblings('.search-form').slideToggle(350);
  });

  $('.accept_all_rules').change(function() {
    var form =   $(this).data('type');
    if(this.checked) {
      $('.toaccept_'+form).each(function(){
        $(this).click();
      });
    } else {
      $('.toaccept_'+form).each(function(){
        $(this).click();
      });
    }
  });


} );
} )( jQuery );

// jQuery(document).ready(function() {
//   if (jQuery(window).width() < 998) { 
//     const elm2 = jQuery("#menu-item-387");
//     const elem_to_m = jQuery("#menu-item-1344");
//     elem_to_m.insertAfter(elm2);
//   }
// });